<template>
  <div class="group-member" v-if="!isProcessing">
    <div class="group-member__total">
      <div class="group-member__total__member">
        <h3 class="group-member__total__member__text">全ユーザー数</h3>
        <p class="group-member__total__member__number">{{ Object.keys(registratedUsers).length }}</p>
      </div>
      <div class="group-member__total__member">
        <h3 class="group-member__total__member__text">例外ユーザー数</h3>
        <p class="group-member__total__member__number">{{ Object.keys(exemptions).length }}</p>
      </div>
    </div>
    <div class="group-member__user" v-for="user in registratedUsers" :key="user.uid">
      <div class="group-member__user__tag" v-if="exemption(user.uid)">
        <p class="group-member__user__tag__text">{{ tagName(exemption(user.uid).taid) }}</p>
      </div>
      <group-user class="group-member__user__detail" :user="user" :email="emails[user.uid]" />
    </div>
  </div>
</template>

<script>
import GroupUser from '@/components/common/user/detail'

export default {
  name: 'group_member',
  components: { GroupUser },
  async mounted () {
    const promises = []
    // 全ユーザー情報の取得
    if (!this.hasGot)promises.push(this.$store.dispatch('users/getUsers'))
    // タグ情報の取得
    if (this.tags.length === 0) promises.push(this.$store.dispatch('tags/getTags'))
    // 例外情報の取得
    if (this.exemptions.length === 0 ) promises.push(this.$store.dispatch('exemptions/getExemptions'))
    // ユーザーのメールアドレス取得
    if (Object.keys(this.emails).length === 0) promises.push(this.$store.dispatch('functions/getAllEmails'))

    await Promise.all(promises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 全ユーザー情報を取得済みかどうか
     */
    hasGot () {
      return this.$store.getters['users/hasGot']
    },
    /**
     * @return {Object[]}タグ情報一覧
     */
    tags () {
      return this.$store.getters['tags/tags']
    },
    /**
     * @return {Object} 全ユーザー情報
     */
    users () {
      return this.$store.getters['users/users']
    },
    /**
     * @return {Object[]} Facebookグループ未加入かつ退会していないユーザー
     */
    registratedUsers () {
      const registratedUsers = {}
      Object.keys(this.users).forEach(key => {
        const user = this.users[key]
        if (user.isFacebookMember && !user.isDeleted) registratedUsers[key] = user
      })
      return registratedUsers
    },
    /**
     * @return {Object[]} 加入済みユーザーの例外情報一覧
     */
    exemptions () {
      const uids = Object.keys(this.registratedUsers).map(key => this.registratedUsers[key].uid)
      return this.$store.getters['exemptions/exemptions'].filter(exemption => uids.includes(exemption.uid))
    },
    /**
     * @return {Object} ユーザーのメールアドレス一覧
     */
    emails () {
      return this.$store.getters.emails
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  },
  methods: {
    /**
     * 例外情報を取得する
     * @param {String} uid ユーザーのID
     * @return {Object} 例外情報
     */
    exemption (uid) {
      return this.exemptions.find(exemption => exemption.uid === uid)
    },
    /**
     * タグ名を取得する
     * @param {String} taid タグのID
     * @return {String} タグ名
     */
    tagName (taid) {
      return this.$store.getters['tags/tag'](taid).name
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.group-member {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin: $header_height auto 0 auto;
  &__total {
    display: flex;
    justify-content: center;
    margin: 0 auto 30px;
    &__member {
      margin: auto 10px;
      text-align: center;
      &__text {
        font-size: 1.2rem;
        font-weight: normal;
      }
      &__number {
        margin: 5px 0 0;
        font-size: 30px;
      }
    }
  }
  &__user {
    position: relative;
    margin: 0 0 20px;
    &:first-child {
      margin-top: 0;
    }
    &__tag {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      min-width: 60px;
      max-width: calc((100% - 10px * 4 - 100px) / 2);
      height: 34px;
      padding: 0 15px;
      margin-left: 10px;
      border: 2px solid $orange_color;
      border-radius: 5px;
      &__text {
        margin: 0;
        overflow: hidden;
        font-size: 1.2rem;
        color: $orange_color;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: $white_color;
      }
    }
  }
  &__warning {
    margin-top: 30px;
    font-size: 1.2rem;
    color: $red_color;
    text-align: center;
  }
}
</style>
